import React, { FC } from 'react';
import {
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Container,
  useTheme,
  Breakpoint,
  DialogActions,
  Button,
  Divider,
} from '@mui/material';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import UserRound from 'assests/icons_svg/user_round.svg';
import { TransitionProps } from '@mui/material/transitions';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogWrapper = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    backgroundColor: theme.palette.background.default,
    border: 'none',
  },
}));

interface DialogProps {
  onClose: () => void;
  children: React.ReactNode;
}

const BootstrapDialogTitle: FC<DialogProps> = (props) => {
  const { children, onClose, ...other } = props;
  const theme = useTheme();
  return (
    <DialogTitle
      sx={{
        m: 0,
        fontSize: '20px',
        fontWeight: '600',
        backgroundColor: theme.palette.background.default,
      }}
      {...other}
    >
      {children}

      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};

interface Props {
  onClose: () => void;
  children: React.ReactNode;
  title?: string | '';
  icon?: JSX.Element;
  header?: React.ReactNode;
  dialogWidth?: Breakpoint | undefined;
  handleAdd?: () => void;
  actions?: boolean;
  actionButtonName?: 'Add' | 'Delete' | 'Save';
  isLoading?: boolean;
}

const CustomDialog: FC<Props> = ({
  onClose,
  handleAdd,
  children,
  title,
  header,
  dialogWidth,
  actions = false,
  actionButtonName,
  isLoading,
}): JSX.Element => {
  const theme = useTheme();
  const [open] = React.useState(true);
  const { t } = useTranslation();

  return (
    <div>
      <DialogWrapper
        disableEnforceFocus={true}
        onClose={onClose}
        maxWidth={dialogWidth ? dialogWidth : 'lg'}
        fullWidth={true}
        open={open}
        sx={{ p: 0, background: theme.colors.blackAlt.main, position: 'fixed' }}
        TransitionComponent={Transition}
      >
        <BootstrapDialogTitle onClose={onClose}>
          {header ? (
            header
          ) : (
            <>
              <Typography sx={{ px: 4, py: 2 }}>{t('User')}</Typography>
              <Container
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'start',
                }}
              >
                <img
                  alt="user_icon"
                  src={UserRound}
                  style={{ marginRight: '10px' }}
                />
                <span>{title || ''}</span>
              </Container>
            </>
          )}
        </BootstrapDialogTitle>
        <DialogContent dividers>{children}</DialogContent>
        {actions && (
          <>
            <Divider />
            <DialogActions
              sx={{ backgroundColor: theme.palette.background.default, pr: 2 }}
            >
              <Button variant="outlined" autoFocus onClick={onClose}>
                {t('Cancel')}
              </Button>
              <Button
                variant="contained"
                autoFocus
                onClick={handleAdd}
                disabled={isLoading}
              >
                {t(actionButtonName ? actionButtonName : 'Add')}
              </Button>
            </DialogActions>
          </>
        )}
      </DialogWrapper>
    </div>
  );
};

export default CustomDialog;
